import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/motorrad/layout";
import SEO from "../../components/motorrad/seo";
import FlexCard from "../../components/motorrad/flexcard";
import DrivingSchoolCard from "../../components/drivingschoolcard";
import HomeVideoBackground from "../../components/homevideobackground";
import settings from "../../../settings";
import Popup from "reactjs-popup";

const bmwmotorradcard = `${settings.IMAGES_BASE_URL}/images/motorrad/home/homepage_rad.jpg`;
const holidayBanner = `${settings.IMAGES_BASE_URL}/images/motorrad/home/moto-holiday.jpg`;
const bmwonroad = `${settings.IMAGES_BASE_URL}/images/motorrad/home/bmw-on-road-motorcycle-course.jpg`;
const bmwoffroad = `${settings.IMAGES_BASE_URL}/images/motorrad/home/bmw-off-road-motorcycle-course.jpg`;
const poster = `${settings.IMAGES_BASE_URL}/images/motorrad/home/poster-image-moto.jpg`;
const creditCard = `${settings.IMAGES_BASE_URL}/images/motorrad/home/moto-cc-home.jpg`;
const womensOneDay = `${settings.IMAGES_BASE_URL}/images/motorrad/classes/womens-1-day.jpg`
const greeceBanner = `${settings.IMAGES_BASE_URL}/images/motorrad/home/moto-tour-greece-banner.jpg`

const videoUrl = `${settings.IMAGES_BASE_URL}/videos/moto.mp4`;
const today = new Date();
const showHolidayBanner = 
  today >= new Date(2024,10,8) && 
  today < new Date(2025,0,1);

const getRowType =(rowNum) => {
  if (rowNum % 2 === 0) {
    return "row-reverse";
  } else {
    return "row";
  }
};

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.rowNum = 0;
  }

  getDefaultCard = () => {
    if(showHolidayBanner) {
      return (
        <FlexCard
          rowType={getRowType(++this.rowNum)}
          image={holidayBanner}
          imageAlt="DASHING THROUGH THE DIRT"
          header="DASHING THROUGH THE DIRT"
          subheader="20% OFF A GIFT CARD"
          text="Make the holidays more epic with 20% off a gift card, good toward many classes and Experiences."
          btnText="CALL 888-345-4269 TO BOOK YOUR CLASS"
          btnStyle="btn-bordered"
          phone="tel:888-345-4269"
        >
          <p>
            Call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> to book your class today with promo code <span className="bold">24RIDHPH20</span>. 
          </p>
          <p>
            Must purchase gift card by 12/31/24. Not valid on Private Training or Two-day MSF Basic Rider Course. Valid at our South Carolina location.
          </p>
        </FlexCard>
      );
    }
  };
  getFirstCard = () => {
    return (
      <>
        <FlexCard
          rowType={getRowType(++this.rowNum)}
          image={greeceBanner}
          imageAlt="RIDE THROUGH GREECE"
          header="RIDE THROUGH GREECE"
        >
          <p>
            The BMW U.S. Rider Academy is excited to offer our 2025 On-Road European Tour in Southern Greece. Join us from September 8th – September 15th, 2025, as we enjoy a variety of excellent roads, delicious local cuisine and the beautiful countryside.
          </p>
          <Link to="/motorrad/touring?desktop=2300&mobile=2200">
            <button className="btn btn-bordered">LEARN MORE</button>
          </Link>
        </FlexCard>
      </>
    );
  };

  getGoProCard =  () => {
    return (
      <FlexCard
          rowType={getRowType(++this.rowNum)}
          image={creditCard}
          imageAlt="BMW Mottorrad Reward"
          header="Rewards At Full Throttle"
          subheader="SAVE ON CLASSES WITH THE BMW MOTORRAD CARD"
      >
        <p>
          Accelerate your rewards with the BMW Motorrad Card – exclusively for BMW Motorrad riders. Redeem for unlimited cash back toward your BMW Motorrad Financial Services monthly payment or travel. Plus, you’ll save when you book a class with us over the phone.
        </p>
        <Link to="https://www.mybmwmotorradcard.com/41627" target="_blank" >
          <button className="btn btn-bordered">LEARN MORE ABOUT THE BMW MOTORRAD CARD</button>
        </Link>
      </FlexCard>
    )
  };
  render() {
    return (
      <Layout>
        <SEO
          title="The BMW U.S. Rider Academy | Motorrad Tours and Classes"
          description="The BMW U.S. Rider Academy offers custom motorrad tours, riding classes – on and off-road, of course, and the chance to ride and try the latest BMW motorcycles."
        />
        <HomeVideoBackground
          headerTitle="Wheels Down, Throttle out, let’s ride"
          url={videoUrl}
          poster={poster}
          motorradvolume="true"
        />
        <section className="performance-center">
          <div className="container">
            <div className="performance-center__header">
              <h1 className="motorrad__title">THE BMW U.S. RIDER ACADEMY</h1>
            </div>
          </div>
          {this.getDefaultCard()}
          {this.getFirstCard()}
          {this.getGoProCard()}
          
        </section>

        <section className="testimonial">
          <div className="testimonial__content">
            <h3>
              “I’ve ridden for years and was amazed at the amount of info I
              didn’t have.”
            </h3>
            <span> - Jeff H.</span>
          </div>
        </section>

        <section className="drivingschool">
          <div className="container">
            <div className="drivingschool__header">
              <h2>BMW RIDING SCHOOLS</h2>
            </div>
          </div>
          <DrivingSchoolCard
            rowType="row"
            image={bmwonroad}
            imageAlt="image of Fast Car"
            header="ON-ROAD"
            subheaderMini="LET THE MOMENTUM GRAB YOU"
            text="Build a momentum of technique, control and comfort in the saddle with every new revolution of the wheel. We’ll cover controlled clutch and throttle movements, low-speed maneuverability, overall balance and more. Every scenario is built to enhance your real-world enjoyment and safety when riding. Rev it up."
            learnUrl="/motorrad/schools?utm_source=MotorradHomePage&utm_medium=banner4&utm_campaign=OnRoadGrab"
            learnBtnStyle="btn-black"
            bookBtnStyle="btn-bordered"
            callBtn="true"
          />
          <DrivingSchoolCard
            rowType="row"
            image={bmwoffroad}
            imageAlt="image of Driver School Car"
            header="OFF-ROAD"
            subheaderMini="OPEN THE DOOR TO NEW ADVENTURES"
            text="Only five percent of the world’s roads are paved, which means one thing: You’re going to get dirty. Off-road classes traverse challenging terrain that leaves students with increased confidence and newfound riding ability."
            learnUrl="/motorrad/schools?utm_source=MotorradHomePage&utm_medium=banner5&utm_campaign=OffRoadDoor"
            learnBtnStyle="btn-black"
            bookBtnStyle="btn-bordered"
            callBtn="true"
          />
        </section>
      </Layout>
    );
  }
}
export default IndexPage;
